import Vue from 'vue'
// import Vuetable from 'vuetable-2'
// import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
// import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
// import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
// import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

// Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from 'moment'


import {FormWizard, TabContent} from 'vue-form-wizard'


export default {
    components: {
        FormWizard,
        TabContent,
        // Vuetable,
        // VuetablePagination,
        // VuetableFieldCheckbox,
        // VuetableFieldMixin
    },
    data: function () {
        return {
            is_visible: true,
            value_etatDossier:'En cours',
            pageTitle: "Liste des tresoreries",
            base_url: this.BASE_URL,
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },

            Liste_compagnie_maritime: [],
            Ouverture: {
                id: this.$route.params.id_dossier == undefined ? 0 : this.$route.params.id_dossier,
                etat_dossier: 0,
                ouverture_date_dossier: moment().format('DD/MM/YYYY'),
                ouverture_date_dossier_formatted: moment().format('DD/MM/YYYY'),
                ouverture_numero_tc: [],
                ouverture_bl_attachement: '',
                ouverture_shipper: '',
                ouverture_bl_numero: "",
                ouverture_invoice_attachement: "",
                ouverture_invoice: '',
                ouverture_packing_list_attachement: "",
                ouverture_packing_list: '',
                ouverture_bsc_attachement: "",
                ouverture_circuit: [],
                ouverture_inspecteur: [],
                ouverture_fournisseur: '',
                ouverture_id_client: '',
                nom_client: '',
                ouverture_poids_brut: '',
                ouverture_poids_net: '',
                ouverture_nombre_colis: '',
                ouverture_nature_colis: '',
                ouverture_articles: '',
                ouverture_aet: '',
                ouverture_ge: '',
                ouverture_bsc: '',
                ecoterme: '',
                ouverture_dom: '',
                ouverture_dom_attachement: '',
                ouverture_id_ecoterme: '',
                ouverture_bsc_date_validation: '',
                ouverture_bsc_date_validation_formatted: '',
                ouverture_compagnie_maritime: '',
                ouverture_compapgnie_maritime_attachement: '',
                ouverture_fret_compagn: '',
                ouverture_navire: '',
                ouverture_prevision: '',
                ouverture_prevision_formatted: '',
                ouverture_commentaire: '',
                ouverture_id_compagnie_maritime: '',
                ouverture_date_arriver: '',
                ouverture_date_arriver_formatted: '',
                nom_compagnie_maritime: '',

                declaration_declaration: '',
                declaration_date: '',
                declaration_date_formatted: '',
                declaration_attachement: '',
                declaration_autres: '',
                declaration_douaneusd: '',
                declaration_commentaire: '',
                declaration_refov: '',
                declaration_date_depotbanque: '',
                declaration_date_depotbanque_formatted: '',
                declaration_etat_paiement: 'NON PAYER',
                declaration_ddtva: 0,
                declaration_tva: 0,
                taux_dd: '',
                taux_tva: '',
                declaration_pg: 0,
                declaration_ars: 0,
                declaration_apmf: 0,
                declaration_dad: 0,
                declaration_dc: 0,
                declaration_amde: 0,
                declaration_total_douane: 0,
                declaration_fobusd: 0,
                declaration_devise:0,
                montant_caisse: 0,
                solde: 0,
                chargement_frais_de_dossier: '',
                chargement_dossier_attachment: '',
                chargmenet_quotient: '',
                chargemnet_BAD: '',
                chargement_BAD_attachement: '',
                chargemnet_mictsl_debarg: '',
                chargement_mictsl_attachment: '',
                chargement_restitution: '',
                chargement_restitution_attachement: '',
                chargement_magasinage: '',
                chargement_magasinage_attachement: '',
                chargement_onverstays: '',
                chargement_surestarie: '',
                chargement_onverstays_attachement: '',
                chargement_surestarie_attachement: '',
                chargement_EHDN: '',
                chargement_EHDN_attachament: '',
                chargement_environnement: '',
                chargement_environnement_attachement: '',
                chargement_gate_remis: '',
                chargement_gate_remis_attachement: '',
                chargement_montant_frais_dossier: 0,
                chargement_montant_BAD: 0,
                chargement_montant_Mictsl: 0,
                chargement_montant_Restitution: 0,
                chargement_montant_magasisage: 0,
                chargement_montant_overstay: 0,
                chargement_montant_ehdn: 0,
                chargement_montant_environnement: 0,
                chargement_montant_surestarie: 0,
                chargement_montant_gate_remise_restitution: 0,
                chargement_cheqocesp_total: 0,
                chargement_photo_visite: '',
                chargement_piece_jointe_visite: '',
                chargement_nom_visite: '',
                chargement_commentaire: '',
                chargement_caution:'',
                chargement_caution_attachement:'',
                tc_en_visite: [],
                chargementsphotos: [],
                photo_visite: [],
                id_tresorerie: '',

                liquidation_date_r: '',
                liquidation_date_r_formatted: '',
                date_fermeture_overstay: '',
                date_fermeture_overstay_formatted: '',
                liquidation_date_l: '',
                liquidation_date_l_formatted: '',
                liquidation_montant_dc: 0,
                liquidation_montant_doc_attachement: '',
                liquidation_date_dc: '',
                liquidation_date_dc_formatted: '',
                liquidation_amende: '',
                liquidation_amende_attachement: '',
                liquidation_date_amende: '',
                liquidation_date_amende_formatted: '',
                liquidation_date_liquidation: '',
                liquidation_date_liquidation_formatted: '',
                liquidation_autre1: '',
                liquidation_autre2: '',
                liquidation_commentaire: '',
                liquidation: '',

                note_prix_date: '',
                note_prix_date_formatted: '',
                note_prix_commentaire: '',
                note_prix_frais_banque: 0,
                note_prix_autres_frais: 0,
                note_prix_transports: 0,
                dossier_tresorerie: [],
            },
            total_credit: 0,
            total_debit: 0,
            solde: 0,
            btn_submit: false,
            // chargements: {
            //     tc_en_visite: [],
            //     chargementsphotos: [],
            //     photo_visite: [],
            //     frais_de_dossier:'',
            //     dossier_attachment:'',
            //     mictsl_debarg:'',
            //     mictsl_attachment:'',
            //     restitution:'',
            //     restitution_attachement:'',
            //     magasinage:'',
            //     magasinage_attachement:'',
            //     onverstays:'',
            //     onverstays_attachement:'',
            //     EHDN:'',
            //     EHDN_attachament:'',
            //     environnement:'',
            //     environnement_attachement:'',
            //     gate_remis:'',
            //     gate_remis_attachement:'',
            //     quotient:0,
            //     BAD:'',
            //     BAD_attachement:'',
            // },
            liquidation: {
                date_r: "",
                date_l: "",
                date_dc: "",
                montant_dc: "",
                amende_attachement: '',
                amende: '',
                montant_doc_attachement: '',
            },
            notedeprix: {},
            total_tc20: 0,
            total_tc40: 0,
            ouverture_inspecteur: [],
            nom_fichier_a_charger: '',
            ecoterme_list: [],
            client_list: [],
            tabpreview_link: [{lien: ''}],
            taux_echange: [],
            facture_detail: [],
            facture: {
                total_facture: 0,
                nom_client: '',
                id_client: '',
                article: '',
                poids_total: '',
                avance_client: 0,
                numero_declaration: '',
                numero_BL: '',
                date_facture: moment().format('DD/MM/YYYY'),
                date_facture_formatted: moment().format('DD/MM/YYYY'),
                date_sortie: moment().format('DD/MM/YYYY'),
                date_sortie_formatted: moment().format('DD/MM/YYYY'),
                lieu_livraison: '',
                autre_numero: '',
                sous_total: '',
                montant_total: '',
                condition_reglement: '',
                montant1: 0,
            },
            liste_tresorerie: [],
            info_societe:{},
        }
    },
    computed:{
        Is_Show(){
            return (this.$parent.role_user=='Super Administrateur' || this.$parent.role_user=='dévéloppeur') ? true : false ;
        }
    },
    methods: {
        GetINfoSociete(){
            axios.get(this.BASE_URL + '/infosociete/get').then((response) => {
                this.info_societe = response.data[0];
            })
        },

        initFacture() {
            this.facture = {
                total_facture: 0,
                nom_client: '',
                id_client: '',
                article: '',
                poids_total: '',
                numero_declaration: '',
                numero_BL: '',
                date_facture: moment().format('DD/MM/YYYY'),
                date_facture_formatted: moment().format('DD/MM/YYYY'),
                date_sortie: moment().format('DD/MM/YYYY'),
                date_sortie_formatted: moment().format('DD/MM/YYYY'),
                lieu_livraison: '',
                autre_numero: '',
                sous_total: '',
                montant_total: '',
                condition_reglement: '',
                montant1: 0,
                avance_client:0,
            }
        },

        imprimerTresorerie() {
            window.open(this.BASE_URL + "/dossier/imprimertresorerie/" + this.Ouverture.id, '_blank');
        },
        imprimerFacture() {
            window.open(this.BASE_URL + "/dossier/imprimerfacture/" + this.Ouverture.id, '_blank');
        },
        selectNomClient() {
            this.facture.nom_client = this.Ouverture.nom_client;
            this.facture.article = this.Ouverture.ouverture_articles;
            this.facture.poids_total = this.Ouverture.ouverture_poids_brut + 'KG';
            this.facture.numero_declaration = this.Ouverture.declaration_declaration;

            this.facture.numero_BL = this.Ouverture.ouverture_bl_numero;
        },
        pushDossierTresorerie() {
            this.Ouverture.dossier_tresorerie.push({
                id: 0,
                id_dossier: 0,
                libeller: '',
                montant_credit: 0,
                montant_debit: 0,
                type_mvt: '',
                date_tresorerie: '',
                date_tresorerie_formatted: '',
            })
        },
        spliceDossierTresorerie(index) {
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    /*  if (value == true) {
                          var link = '';
                          this.btn_submit = true;
                          link = this.Ouverture.id == '' ? this.BASE_URL + "/dossier/addaction" : this.BASE_URL + "/dossier/editaction";
                          axios.post(link, $("#formulaire_ouverture").serialize()).then((response)=> {
                              this.btn_submit = false;
                              //this.message_reponse = response.data.msg;
                              if (response.data.error === "") {
                                  console.log("Enregistrement effectué avec succès!");
                                  Vue.$toast.open({
                                      message: 'Enregistrement effectué avec succès!',
                                      type: 'success',
                                      position: 'top-right'
                                      // all of other options may go here
                                  });
                                  this.getDossier(response.data.id_dossier)
                              } else {
                                  console.log("Erreur");
                                  Vue.$toast.open({
                                      message: response.data.error,
                                      type: 'error',
                                      position: 'top-right'
                                      // all of other options may go here
                                  });
                              }
                          });
                      }*/
                    this.Ouverture.dossier_tresorerie.splice(index, 1);
                    this.CalculSoldeTresorerie();
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });


        },

        CalculSoldeTresorerie() {
            var total_debit = 0, total_credit = 0;
            this.Ouverture.dossier_tresorerie.forEach(value => {
                total_debit += parseFloat(value.montant_debit);
                total_credit += parseFloat(value.montant_credit);
            });
            this.total_debit = total_debit;
            this.total_credit = total_credit;
            this.solde = total_credit
                - total_debit;

        },

        initFactureDetail() {
            this.facture_detail = [
                {id: 0, id_dossier: 0, ref: 'BSC', description: 'création', montant: 0},
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'SHIPPING COMPANY',
                    description: 'Frais de dossier',
                    montant: this.Ouverture.chargement_montant_frais_dossier
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'SHIPPING COMPANY',
                    description: 'caution',
                    montant: this.Ouverture.chargmenet_quotient
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'MICTSL',
                    description: 'DEBARQUEMENT',
                    montant: this.Ouverture.chargement_montant_Mictsl
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'RESTITUTION',
                    description: 'RESTITUTION',
                    montant: this.Ouverture.chargement_montant_Restitution
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: '',
                    description: 'magasinage',
                    montant: this.Ouverture.chargement_montant_magasisage
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'OV',
                    description: 'TAXE + GN + ARS + APMF',
                    montant: this.Ouverture.declaration_total_douane
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'BSC',
                    description: 'COMMISSION DE TRANSIT',
                    montant: 0
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'surestarie',
                    description: 'surestarie',
                    montant: this.Ouverture.chargement_montant_surestarie
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'overstays',
                    description: 'overstays',
                    montant: this.Ouverture.chargement_montant_overstay
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'ehdn',
                    description: 'ehdn',
                    montant: this.Ouverture.chargement_montant_ehdn
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'AET',
                    description: 'AET',
                    montant: this.Ouverture.ouverture_aet
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'PIM ',
                    description: 'PIM',
                    montant: this.Ouverture.ouverture_aet
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'PHYTOSANITAIRE ',
                    description: 'PHYTOSANITAIRE',
                    montant: 0
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'SOUMISSION ',
                    description: 'SOUMISSION',
                    montant: 0
                },
                {
                    id: 0,
                    id_dossier: 0,
                    ref: 'PRELEVEMENT ',
                    description: 'PRELEVEMENT',
                    montant: 0
                },
            ];
            this.calculMontantFacture();
        },
        calculMontantFacture() {
            var total_montant = 0;
            this.facture_detail.forEach(value => {
                total_montant += parseFloat(value.montant);
            });
            this.facture.total_facture = total_montant + parseFloat(this.facture.montant1);
            this.facture.sous_total = this.facture.total_facture;
            console.log("liste_facture",this.facture);
            this.facture.montant_total = this.facture.sous_total - parseFloat(this.facture.avance_client);
        },
        pushFacture_detail() {
            this.facture_detail.push({
                id: 0,
                id_dossier: 0,
                ref: '',
                description: '',
                montant: 0,
            })
        },
        spliceDetail(index) {
            this.facture_detail.splice(index, 1);
        },
        changerEtaPaiement(etat_paiement) {
            this.Ouverture.declaration_etat_paiement = etat_paiement;
        },
        getTauxEchange() {
            axios.get(this.BASE_URL + '/tauxechange/fetchdata').then((response) => {
                this.taux_echange = response.data.tauxechange;
                this.Ouverture.declaration_devise = response.data.tauxechange[0].taux_montant;
            })
        },

        getTresorerie() {
            axios.get(this.BASE_URL + '/tresoreries/getalltresorerie').then((response) => {
                this.liste_tresorerie = response.data;
            })
        },

        calculMontantDD() {
            this.Ouverture.taux_dd = (this.Ouverture.taux_dd == '') ? 0 : this.Ouverture.taux_dd;
            this.Ouverture.declaration_douaneusd = (this.Ouverture.declaration_douaneusd == '') ? 0 : this.Ouverture.declaration_douaneusd;
            this.Ouverture.declaration_fobusd = (this.Ouverture.declaration_fobusd == '') ? 0 : this.Ouverture.declaration_fobusd;

            var montant_ariary = (parseFloat(this.Ouverture.declaration_douaneusd) + parseFloat(this.Ouverture.declaration_fobusd)) * this.Ouverture.declaration_devise;
            this.Ouverture.declaration_ddtva = (montant_ariary * parseFloat(this.Ouverture.taux_dd)) / 100 ?? 0;

            this.Ouverture.taux_tva = (this.Ouverture.taux_tva == '') ? 0 : this.Ouverture.taux_tva;

            var montant_ariary_tva = (parseFloat(this.Ouverture.declaration_douaneusd) + parseFloat(this.Ouverture.declaration_fobusd)) * this.Ouverture.declaration_devise;
            this.Ouverture.declaration_tva = (montant_ariary_tva * parseFloat(this.Ouverture.taux_tva)) / 100 ?? 0


            this.totalDOuane();
        },

        /**
         * fonction permet de recuperer tout les dossier
         * @param id_dossier
         */
        getDossier(id_dossier) {
            axios.get(this.BASE_URL + '/dossier/get/' + id_dossier).then((response) => {
                this.Ouverture = response.data[0];
                this.is_visible = response.data[0].id_navire_bl == 0 ? true : false;
                this.value_etatDossier = (this.Ouverture.etat_dossier ==0 ) ? 'En cours' : 'Clôturer ';
                if (this.Ouverture.ouverture_numero_tc.length == 0)
                    this.Ouverture.ouverture_numero_tc = [{tc20: "", tc40: ""}];
                if (this.Ouverture.ouverture_circuit.length == 0)
                    this.Ouverture.ouverture_circuit = [{circuit: "", date_circuit: "", circuit_commentaire: ""}];
                if (this.Ouverture.ouverture_inspecteur.length == 0)
                    this.Ouverture.ouverture_inspecteur = [{nom_inspecteur: "", date_inspecteur: ""}];
                if (this.Ouverture.tc_en_visite.length == 0)
                    this.addNumTCEnVisite();
                if (this.Ouverture.photo_visite.length == 0)
                    this.addPhotoVisite();
                if (this.Ouverture.dossier_tresorerie.length == 0)
                    this.pushDossierTresorerie();
                this.CalculSoldeTresorerie();

                if (response.data[0].facture.length <= 0) {
                    this.initFacture();
                    this.initFactureDetail();
                } else {
                    this.facture = response.data[0].facture[0];
                    this.facture_detail = response.data[0].facture_detail;
                }

                this.selectNomClient();
                // this.initFactureDetail();
            })
        },

        changerEtatDossier() {
            this.Ouverture.etat_dossier = (this.Ouverture.etat_dossier == 0) ? 1 : 0;
            this.value_etatDossier = (this.Ouverture.etat_dossier ==0 ) ? 'En cours' : 'Clôturer ';
            axios.get(this.BASE_URL + "/dossier/changeetatdossier/"+this.Ouverture.id).then(response => {
                console.log(response);
            });
        },
        totalDOuane() {

            this.Ouverture.declaration_total_douane = parseFloat(this.Ouverture.declaration_ddtva) +
                parseFloat(this.Ouverture.declaration_ars) +
                parseFloat(this.Ouverture.declaration_pg) +
                parseFloat(this.Ouverture.declaration_apmf) +
                parseFloat(this.Ouverture.declaration_dad) +
                parseFloat(this.Ouverture.declaration_dc) +
                parseFloat(this.Ouverture.declaration_tva) +
                parseFloat(this.Ouverture.declaration_amde);
        },

        getCompagnieMaritime() {
            axios.get(this.BASE_URL + "/compagnie/fetchdata").then(response => {
                this.Liste_compagnie_maritime = response.data.compagnie;
            });
        },
        getEcoterme() {
            axios.get(this.BASE_URL + "/ecoterme/fetchdata").then(response => {
                this.ecoterme_list = response.data.ecoterme;
            });
        },
        getclient() {
            axios.get(this.BASE_URL + "/client/fetchdata").then(response => {
                this.client_list = response.data.client;
            });
        },
        openModal() {
            this.$bvModal.show("crudData");
        },
        closeModal() {
            this.$bvModal.hide("crudData");
        },

        /**
         *
         * fonction permet d'enregistre Ouverture
         */
        onSubmitOuverture() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.Ouverture.id == '' ? this.BASE_URL + "/dossier/addaction" : this.BASE_URL + "/dossier/editaction";
                        axios.post(link, $("#formulaire_ouverture").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        onSubmiTresorerie() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/savetresorerie";
                        axios.post(link, $("#formulaire_tresorerie").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitChargement() {
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/savechargement";
                        axios.post(link, $("#formulaire_chargement").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitDeclaration() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/savedeclaration";
                        axios.post(link, $("#formulaire_declaration").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitLiquidation() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        link = this.BASE_URL + "/dossier/saveliquidation";
                        axios.post(link, $("#formulaire_liquidation").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },

        onSubmitNotePrix() {

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        this.btn_submit = true;
                        // link = this.BASE_URL + "/dossier/savenoteprix";
                        link = this.BASE_URL + "/dossier/savefacture";
                        axios.post(link, $("#formulaire_note_prix").serialize()).then((response) => {
                            this.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                this.getDossier(response.data.id_dossier)
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        onSubmit_() {

        },
        chargePieceJointe(name) {
            this.nom_fichier_a_charger = name;
            $("#piece_jointe").trigger('click');
        },
        resetPiecejointe(name) {
            if (name == 'ouverture_bl_attachement') this.Ouverture.ouverture_bl_attachement = '';
            if (name == 'ouverture_invoice_attachement') this.Ouverture.ouverture_invoice_attachement = '';
            if (name == 'ouverture_packing_list_attachement') this.Ouverture.ouverture_packing_list_attachement = '';
            if (name == 'ouverture_bsc_attachement') this.Ouverture.ouverture_bsc_attachement = '';
            if (name == 'declaration_attachement') this.Ouverture.declaration_attachement = '';
            if (name == 'chargement_dossier_attachment') this.Ouverture.chargement_dossier_attachment = '';
            if (name == 'chargement_mictsl_attachment') this.Ouverture.chargement_mictsl_attachment = '';
            if (name == 'chargement_restitution_attachement') this.Ouverture.chargement_restitution_attachement = '';
            if (name == 'chargement_magasinage_attachement') this.Ouverture.chargement_magasinage_attachement = '';
            if (name == 'chargement_onverstays_attachement') this.Ouverture.chargement_onverstays_attachement = '';
            if (name == 'chargement_surestarie_attachement') this.Ouverture.chargement_surestarie_attachement = '';
            if (name == 'chargement_EHDN_attachament') this.Ouverture.chargement_EHDN_attachament = '';
            if (name == 'chargement_environnement_attachement') this.Ouverture.chargement_environnement_attachement = '';
            if (name == 'chargement_gate_remis_attachement') this.Ouverture.chargement_gate_remis_attachement = '';
            if (name == 'liquidation_amende_attachement') this.Ouverture.liquidation_amende_attachement = '';
            if (name == 'liquidation_montant_doc_attachement') this.Ouverture.liquidation_montant_doc_attachement = '';
            if (name == 'chargement_BAD_attachement') this.Ouverture.chargement_BAD_attachement = '';
            if (name == 'ouverture_dom_attachement') this.Ouverture.ouverture_dom_attachement = '';
            if (name == 'ouverture_compapgnie_maritime_attachement') this.Ouverture.ouverture_compapgnie_maritime_attachement = '';
            if (name == 'chargement_piece_jointe_visite') this.Ouverture.chargement_piece_jointe_visite = '';
            if (name == 'chargement_caution_attachement') this.Ouverture.chargement_caution_attachement = '';


            this.nom_fichier_a_charger = '';
        },
        createImagesVisite(file, index) {
            var reader = new FileReader();
            var that = this;
            reader.onload = (e) => {
                that.tabpreview_link[index].lien = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        uploadImageVisite(event, index) {
            const URL = this.BASE_URL + '/import/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImagesVisite(files[0], index);

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    that.Ouverture.photo_visite[index].url = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },
        createImages(file) {
            var reader = new FileReader();
            var that = this;
            reader.onload = (e) => {
                that.preview_link = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        uploadImage(event) {
            const URL = this.BASE_URL + '/import/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImages(files[0]);

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    if (that.nom_fichier_a_charger == 'ouverture_bl_attachement')
                        that.Ouverture.ouverture_bl_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_invoice_attachement')
                        that.Ouverture.ouverture_invoice_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_packing_list_attachement')
                        that.Ouverture.ouverture_packing_list_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_bsc_attachement')
                        that.Ouverture.ouverture_bsc_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'declaration_attachement')
                        that.Ouverture.declaration_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_dossier_attachment')
                        that.Ouverture.chargement_dossier_attachment = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_mictsl_attachment')
                        that.Ouverture.chargement_mictsl_attachment = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_restitution_attachement')
                        that.Ouverture.chargement_restitution_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_magasinage_attachement')
                        that.Ouverture.chargement_magasinage_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_onverstays_attachement')
                        that.Ouverture.chargement_onverstays_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_surestarie_attachement')
                        that.Ouverture.chargement_surestarie_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_EHDN_attachament')
                        that.Ouverture.chargement_EHDN_attachament = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_environnement_attachement')
                        that.Ouverture.chargement_environnement_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_gate_remis_attachement')
                        that.Ouverture.chargement_gate_remis_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'liquidation_amende_attachement')
                        that.Ouverture.liquidation_amende_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'liquidation_montant_doc_attachement')
                        that.Ouverture.liquidation_montant_doc_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_BAD_attachement')
                        that.Ouverture.chargement_BAD_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_dom_attachement')
                        that.Ouverture.ouverture_dom_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'ouverture_compapgnie_maritime_attachement')
                        that.Ouverture.ouverture_compapgnie_maritime_attachement = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_piece_jointe_visite')
                        that.Ouverture.chargement_piece_jointe_visite = response.data.upload_data.file_name;

                    if (that.nom_fichier_a_charger == 'chargement_caution_attachement')
                        that.Ouverture.chargement_caution_attachement = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },


        validerOuverture() {
            return true;
        },
        validerDeclaration() {
            return true;
        },
        validerChargement() {
            return true;
        },
        validerLiquidation() {
            return true;
        },
        validerDebours52() {
            return true;
        },
        onComplete() {

        },
        addNumTC() {
            // if (this.Ouverture.ouverture_numero_tc.length < 10) {
                this.Ouverture.ouverture_numero_tc.push(
                    {
                        tc20: "",
                        tc40: ""
                    });

            // }
        },
        countTC() {
            console.log(this.total_tc20 + " -- " + this.total_tc40);
            var tc20 = 0;
            var tc40 = 0;
            $.each(this.Ouverture.ouverture_numero_tc, function (key, value) {
                console.log(value);
                if (value.tc20 != "") tc20 = Number(tc20) + 1;
                if (value.tc40 != "") tc40 = Number(tc40) + 1;
            });
            this.total_tc20 = tc20;
            this.total_tc40 = tc40;
        },
        addCircuit() {
            this.Ouverture.ouverture_circuit.push({circuit: "", date_circuit: "", circuit_commentaire: ""});
        },
        addInspecteur() {
            this.Ouverture.ouverture_inspecteur.push({nom_inspecteur: "", date_inspecteur: ""});
        },
        addNumTCEnVisite() {
            this.Ouverture.tc_en_visite.push(
                {
                    transporteur: "",
                    dep_camion: "",
                    lieut_livraison: "",
                    statut: "",
                    plomb_fermeture: "",
                    montant_ar: ""
                });
        },
        addPhotoVisite() {
            this.Ouverture.photo_visite.push(
                {
                    url: ""
                });
            this.tabpreview_link.push({lien: ''})
        },
        splicePhotos_visite(index) {
            this.Ouverture.photo_visite.splice(index, 1);
            this.tabpreview_link.splice(index, 1);
        },
        choosePhotoVisite(liste, index) {
            $('#photo_visite_' + index).trigger('click');
            liste;
        },

        removeCircuit(index) {
            this.Ouverture.ouverture_circuit.splice(index, 1);
        },

        removeInspecteur(index) {
            this.Ouverture.ouverture_inspecteur.splice(index, 1);
        },

        calculTotalCOMPTE() {
            this.Ouverture.chargement_cheqocesp_total = parseFloat(this.Ouverture.chargement_montant_ehdn)
                + parseFloat(this.Ouverture.chargement_montant_overstay)
                + parseFloat(this.Ouverture.chargement_montant_magasisage)
                + parseFloat(this.Ouverture.chargement_montant_Restitution)
                + parseFloat(this.Ouverture.chargement_montant_Mictsl)
                + parseFloat(this.Ouverture.chargement_montant_BAD)
                + parseFloat(this.Ouverture.chargement_montant_frais_dossier)
                + parseFloat(this.Ouverture.chargement_montant_gate_remise_restitution)
                + parseFloat(this.Ouverture.chargement_montant_environnement)
                + parseFloat(this.Ouverture.chargement_montant_surestarie)
                + parseFloat(this.Ouverture.chargmenet_quotient)
        },
    },

    watch: {
        // "Ouverture.ouverture_numero_tc.tc20": function() {
        //     this.countTC();
        // },
        // "Ouverture.ouverture_numero_tc.tc40": function() {
        //     this.countTC();
        // }
    },
    created: function () {
        this.$emit('change-page', this.pageTitle);
        if (this.Ouverture.ouverture_numero_tc.length == 0)
            this.Ouverture.ouverture_numero_tc = [{tc20: "", tc40: ""}];
        if (this.Ouverture.ouverture_circuit.length == 0)
            this.Ouverture.ouverture_circuit = [{circuit: "", date_circuit: "", circuit_commentaire: ""}];
        if (this.Ouverture.ouverture_inspecteur.length == 0)
            this.Ouverture.ouverture_inspecteur = [{nom_inspecteur: "", date_inspecteur: ""}];
        if (this.Ouverture.tc_en_visite.length == 0)
            this.addNumTCEnVisite();
        if (this.Ouverture.photo_visite.length == 0)
            this.addPhotoVisite();


        this.getCompagnieMaritime();
        this.getEcoterme();
        this.getclient();
        this.getTauxEchange();
        this.getTresorerie();
        this.pushDossierTresorerie();
    },
    mounted: function () {
        this.initFactureDetail();
        this.GetINfoSociete();
        // setTimeout(()=>{
        //     this.validerOuverture();
        //     this.validerDeclaration();
        //     this.validerChargement();
        //     this.validerLiquidation();
        //     this.validerDebours52();
        // },2000)
        this.validerDeclaration();

        if (parseInt(this.$route.params.id_dossier) > 0) {
            this.getDossier(this.$route.params.id_dossier)
        }
    }
}
